var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('section', {
    staticClass: "login-section"
  }, [_c('div', {
    staticClass: "login-blk"
  }, [_c('div', {
    staticClass: "screen-lft"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/cp-logo-new.png"),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "screen-rgt"
  }, [_c('div', {
    staticClass: "screen-outer"
  }, [_c('div', {
    staticClass: "screen-title"
  }, [_c('h2', {
    staticClass: "font-18px"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_reset_password_title")) + " ")])]), _c('div', {
    staticClass: "screen-form"
  }, [_c('form', [_c('v-text-field', {
    staticClass: "input-text",
    attrs: {
      "placeholder": _vm.$t('page_reset_password_email_placeholder'),
      "filled": "",
      "rounded": "",
      "dense": ""
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('v-text-field', {
    staticClass: "input-text",
    attrs: {
      "placeholder": _vm.$t('page_reset_password_password_placeholder'),
      "filled": "",
      "rounded": "",
      "dense": "",
      "type": _vm.showPassword ? 'text' : 'password',
      "hide-details": "auto"
    },
    model: {
      value: _vm.new_password,
      callback: function callback($$v) {
        _vm.new_password = $$v;
      },
      expression: "new_password"
    }
  }), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('v-text-field', {
    staticClass: "input-text",
    attrs: {
      "placeholder": _vm.$t('page_reset_password_password_confirm_placeholder'),
      "filled": "",
      "rounded": "",
      "dense": "",
      "type": _vm.showPassword ? 'text' : 'password',
      "hide-details": "auto"
    },
    model: {
      value: _vm.confirm_password,
      callback: function callback($$v) {
        _vm.confirm_password = $$v;
      },
      expression: "confirm_password"
    }
  }), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('v-btn', {
    staticClass: "text-capitalize btn-submit",
    attrs: {
      "block": "",
      "large": "",
      "color": "primary",
      "disabled": !_vm.isValid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("submit")))]), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('div', {
    staticClass: "forget-blk"
  }, [_c('span', {
    staticClass: "forgot-text"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("page_reset_password_login_redirect_text")) + " ")])], 1)])], 1)])])])])]), _c('div', {
    staticClass: "copyright-blk"
  }, [_c('p', [_vm._v("© 2017 - 2021 redish Co., Ltd.")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }