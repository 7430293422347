<template>
  <v-app>
    <section class="login-section">
      <div class="login-blk">
        <div class="screen-lft">
          <div class="logo">
            <a href="#">
              <img src="@/assets/images/cp-logo-new.png" alt="" />
            </a>
          </div>
        </div>
        <div class="screen-rgt">
          <div class="screen-outer">
            <div class="screen-title">
              <h2 class="font-18px">
                {{ $t("page_reset_password_title") }}
              </h2>
            </div>
            <div class="screen-form">
              <form>
                <v-text-field
                  :placeholder="$t('page_reset_password_email_placeholder')"
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="email"
                ></v-text-field>

                <div class="marin-bottom-field"></div>
                <v-text-field
                  :placeholder="$t('page_reset_password_password_placeholder')"
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="new_password"
                  :type="showPassword ? 'text' : 'password'"
                  hide-details="auto"
                ></v-text-field>

                <div class="marin-bottom-field"></div>
                <v-text-field
                  :placeholder="
                    $t('page_reset_password_password_confirm_placeholder')
                  "
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="confirm_password"
                  :type="showPassword ? 'text' : 'password'"
                  hide-details="auto"
                ></v-text-field>

                <div class="marin-bottom-field"></div>
                <v-btn
                  block
                  class="text-capitalize btn-submit"
                  large
                  color="primary"
                  :disabled="!isValid"
                  @click="submit"
                  >{{ $t("submit") }}</v-btn
                >
                <div class="marin-bottom-field"></div>
                <div class="forget-blk">
                  <span class="forgot-text">
                    <router-link :to="{ name: 'Login' }">
                      {{ $t("page_reset_password_login_redirect_text") }}
                    </router-link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright-blk">
      <p>© 2017 - 2021 redish Co., Ltd.</p>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
      showPassword: false,
      new_password: null,
      confirm_password: null,
      isValid: true,
      rules: {
        new_password: [
          v =>
            !!v ||
            this.$t("page_reset_password_password_required_validation_text"),
          v =>
            (v && v.length >= 6) ||
            this.t("page_reset_password_password_min_validation_text")
        ],
        confirm_password: [
          v =>
            !!v ||
            this.$t(
              "page_reset_password_password_new_required_validation_text"
            ),
          v =>
            (v && v.length >= 6) ||
            this.$t("page_reset_password_password_min_validation_text"),
          v =>
            v === this.new_password ||
            this.$t("page_reset_password_password_matches_validation_text")
        ]
      }
    };
  },

  methods: {
    submit() {
      let email = this.email;
      let token = this.$route.params.token;
      let password_confirmation = this.confirm_password;
      let password = this.new_password;
      this.$store
        .dispatch("AUTH_RESET", {
          email,
          password_confirmation,
          password,
          token
        })
        .then(
          response => {
            if (response.status >= 200 && response.status <= 299) {
              this.$store.dispatch("ALERT", {
                show: true,
                text: this.$t("page_reset_password_success_text")
              });
              this.email = "";
              this.new_password = "";
              this.confirm_password = "";
            } else {
              this.$store.dispatch("ALERT", {
                show: true,
                text: this.$t("page_reset_password_error")
              });
            }
          },
          error => {
            console.error(error);
            this.$store.dispatch("ALERT", {
              show: true,
              text: this.$t("page_reset_password_error")
            });
          }
        );
    },
    login() {}
  }
};
</script>

<style lang="scss" src="./_styles.scss"></style>
